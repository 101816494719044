<template>
	<div>
		<div class="members-profile">
			<v-container class="first-part">
				<Breadcrumbs v-if="member" />

				<v-row>
					<v-col class="d-flex pb-0 mb-12">
						<v-icon
							large
							:color="
								this.$store.getters['auth/user']
									? this.$store.getters['auth/user'].theme_config.primary_color
									: ''
							"
							>mdi-account-question</v-icon
						>
						<h1 class="d-flex ml-2 page-title pt-3">
							{{ $t("questionnaires") }}
						</h1>
					</v-col>
				</v-row>

				<BasicInfo :isLifeChamps="isLifeChamps" :member="member" />
			</v-container>

			<v-container
				fluid
				:class="`medical-info-container ${isLifeChamps ? 'mt-8' : 'mt-16'}`"
			>
				<v-container>
					<v-tabs v-model="tab" align-with-title class="mb-7 custom-tabs">
						<v-tabs-slider
							:color="
								$store.getters['auth/user']
									? $store.getters['auth/user'].theme_config.primary_color
									: ''
							"
						></v-tabs-slider>

						<v-tab v-for="item in items" :key="item">
							{{ item }}
						</v-tab>
					</v-tabs>

					<v-tabs-items v-model="tab" class="pl-5">
						<!-- the next line is commented for demo purpose and will uncommented after using dynamic component -->
						<!-- <v-skeleton-loader
            v-if="!questionnaire.questionnaire_id"
            type="card-heading, image"
          ></v-skeleton-loader> -->
						<v-tab-item>
							<v-container>
								<component
									:is="questionnaire.type"
									:questionnaire="questionnaire"
									v-if="questionnaire"
								/>
							</v-container>
						</v-tab-item>
					</v-tabs-items>
					<v-tab-item v-if="hasProileTab"></v-tab-item>

					<v-tab-item v-if="hasHistoryTab">
						<History />
					</v-tab-item>

					<v-tab-item v-if="hasMessagesTab">
						<Messages :messages="messages" />
					</v-tab-item>
				</v-container>
			</v-container>
		</div>
	</div>
</template>

<script>
import { mapActions } from "vuex";

const components = {
	Breadcrumbs: () => import("@/components/Breadcrumbs.vue"),
	"phq-4": () =>
		import(
			"@/components/views/dashboard/members/id/partial/questionnaires/PHQ.vue"
		),
	"iief-6": () =>
		import(
			"@/components/views/dashboard/members/id/partial/questionnaires/PHQ.vue"
		),
	mars: () =>
		import(
			"@/components/views/dashboard/members/id/partial/questionnaires/MARS.vue"
		),
	lasa: () =>
		import(
			"@/components/views/dashboard/members/id/partial/questionnaires/LASA.vue"
		),
	"ves-13": () =>
		import(
			"@/components/views/dashboard/members/id/partial/questionnaires/VES.vue"
		),
	esas: () =>
		import(
			"@/components/views/dashboard/members/id/partial/questionnaires/ESAS.vue"
		),
	haq: () =>
		import(
			"@/components/views/dashboard/members/id/partial/questionnaires/HAQ.vue"
		),
	vgp: () =>
		import(
			"@/components/views/dashboard/members/id/partial/questionnaires/VGP.vue"
		),
	"general-questions": () =>
		import(
			"@/components/views/dashboard/members/id/partial/questionnaires/GeneralQuestions.vue"
		),
	das: () =>
		import(
			"@/components/views/dashboard/members/id/partial/questionnaires/DAS.vue"
		),
	History: () =>
		import("@/components/views/dashboard/members/id/partial/tabs/History.vue"),
	Messages: () =>
		import("@/components/views/dashboard/members/id/partial/tabs/Messages.vue"),
	BasicInfo: () => import("@/components/shared/BasicInfo.vue"),
};

export default {
	name: "questionnaire",

	data() {
		return {
			tab: null,
			questionnaire: null,
			messages: [
				{
					date: 11,
					type: "type",
					message: "test",
					read: "now",
				},
			],
		};
	},

	computed: {
		member: function () {
			return this.$store.getters["members/member"];
		},
		isLifeChamps: function () {
			return (
				this.$store.getters["auth/user"]?.theme_config.key == "lifechamps"
			);
		},
		hasProileTab() {
			return this.$store.getters["auth/hasProileTab"];
		},
		hasMessagesTab() {
			return this.$store.getters["auth/hasMessagesTab"];
		},
		hasHistoryTab() {
			return this.$store.getters["auth/hasHistoryTab"];
		},
		items() {
			let tabs = [this.$t("health")];

			if (this.hasProileTab) {
				tabs.push(this.$t("profile"));
			}

			if (this.hasHistoryTab) {
				tabs.push(this.$t("history"));
			}

			if (this.hasMessagesTab) {
				tabs.push(this.$t("messages"));
			}

			return tabs;
		},
	},

	async created() {
		this.getMember();
		this.questionnaire = await this.getQuestionnaire();
		this.setQuestionnaireToBreadcrumbs();
		this.$store.dispatch("logActivity", {
			tag: "memeber_profile_questionnaire_detail",
			type: 1,
			reference_id: this.$route.params.id,
			// To DO add uncomment this line after integrate this page with BE using questionnaire id
			// questionnaire_id: this.$route.params.questionnaireId,
		});
	},

	destroyed() {
		this.$store.dispatch("logActivity", {
			tag: "memeber_profile_questionnaire_detail",
			type: 2,
			reference_id: this.$route.params.id,
			// To DO add uncomment this line after integrate this page with BE using questionnaire id
			// questionnaire_id: this.$route.params.questionnaireId,
		});
	},

	components,

	methods: {
		...mapActions({
			getMember: "members/getMember",
			getQuestionnaire: "questionnaires/getQuestionnaire",
		}),
		setQuestionnaireToBreadcrumbs() {
			this.$route.meta.breadcrumbs[2].text = this.$route.params.questionnaire;
			this.$route.meta.breadcrumbs[2].translatedText = this.questionnaire.type;
		},
	},
};
</script>

<style lang="scss" src="@/assets/sass/views/dashboard/members/id.scss"></style>
